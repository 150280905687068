import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/demo',
  //   name: 'home',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/home.vue'),
  // },
  {
    path: '/',
    name: 'demo',
    component: () => import(/* webpackChunkName: "demo" */ '../views/demo.vue'),
  },
  {
    path: '/forex',
    name: 'forex',
    component: () => import(/* webpackChunkName: "markets" */ '../views/markets/forex.vue'),
  },

  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base:'/ift.capital/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
