<!--  -->
<template>
  <div ref="footer" class="footer">
    <div class="partner i-fixedWidth">
      <transition name="under-into">
        <div class="title" v-if="showFooter[0]">{{ $t('Main.Main98') }}</div>
      </transition>
      <transition name="under-into">
        <div v-show="showFooter[1]" class="partnerContent u-flex u-row-between">
          <template v-for="(src, index) in partnerImgList">
            <div v-if="index <= 4" :key="index" class="partnersItem"><img :src="src" /></div>
          </template>
        </div>
      </transition>

      <transition name="under-into">
        <div v-show="showFooter[2]" class="partnerContent u-flex u-row-between">
          <template v-for="(src, index) in partnerImgList">
            <div v-if="index > 4" :key="index" class="partnersItem"><img :src="src" /></div>
          </template>
        </div>
      </transition>
    </div>
    <div class="disclaimerContent">
      <div class="disclaimer i-fixedWidth">
        <div class="disclaimer-title">{{ $t('Main.Main99') }}</div>
        <div class="disclaimer-subTitle subTitleFontFamily">{{ $t('Main.Main100') }}</div>

        <div class="u-flex">
          <img :src="$t('image.footerLogo')" />
          <div class="nav">
            <div>
              <a href="https://www.is.capital/resource/risk.pdf">{{ $t('Main.Main101') }}</a> | <a href="https://www.is.capital/resource/anti.pdf">{{ $t('Main.Main102') }}</a> | <a href="https://www.is.capital/resource/privacy.pdf">{{ $t('Main.Main103') }}</a> | <a href="https://www.is.capital/resource/client.pdf">{{ $t('Main.Main104') }}</a> <br />
              <a href="https://my.is.capital/zh/register">{{ $t('Main.Main105') }}</a>
            </div>
            <div class="email">
              <div>{{ $t('Main.Main106') }}</div>
              <div>{{ $t('Main.Main107') }}</div>
              <div>{{ $t('Main.Main108') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="copyright">{{ $t('copyright') }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { scrollMixins } from '@/utils/mixins';
let debuggerFLag = false
export default {
  mixins: [scrollMixins],
  data() {
    return {
      partnerImgList: [],
      showFooter: [debuggerFLag, debuggerFLag, debuggerFLag],
    };
  },

  components: {},

  computed: {
    ...mapGetters(['json']),
  },
  watch: {
    json: {
      handler: function (json) {
        this.partnerImgList = json.partner;
      },
      immediate: true,
    },
  },
  mounted() {
    // console.log(this.json);
    this.handleScroll();
  },

  methods: {
    handleScroll() {
      let footerShow = this.isElementsVisible(this.$refs.footer);
      if (footerShow) {
        this.animationList(this.showFooter);
      } else {
        this.showFooter = this.$options.data().showFooter;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.footer {
  width: 100%;
  background-color: #fff;


  .partner {
    height: 400px;
    background-color: #fff;
    margin-top: 84.45px;
    overflow: hidden;

    .partnerContent {
      width: 877.55px;
      margin: 42px auto;
      .partnersItem {
        flex: 1;
        margin-right: 30px;
      }
      img {
        width: 100%;
      }
    }
    .title {
      text-align: center;
      margin-bottom: 72px;
      font-size: 37px;
      font-family: Roboto-Bold;
      font-weight: bold;
      color: #000810;
      line-height: 42px;
    }
  }
  .disclaimerContent {
    background-color: #f6f6f6;
  }

  .disclaimer {
    padding-top: 80px;
    padding-bottom: 20px;
    width: 1100px;
    .disclaimer-title {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 8px;
    }
    .disclaimer-subTitle {
      color: #333;
    }
    img {
      width: 150px;
    }
  }
  .nav {
    padding: 40px;
    font-size: 14px;
    a {
      color: #337ab7;
      &:hover {
        color: #23527c;
      }
    }
  }
  .email {
    margin-top: 15px;
    line-height: 20px;
    color: #333;
    font-size: 12px;
  }
  .copyright {
    background-color: #001649;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 10px;
    color: rgb(210, 210, 210);
  }
}
</style>
