<!--  -->
<template>
  <div :class="['header']">
    <div class="language-nav u-flex u-row-right">
      <div class="language-nav-right u-flex i-fixedWidth">
        <div class="contactUs hoverScale">{{ $t('Main.Main29') }}</div>
        <el-popover placement="top-start" trigger="hover">
          <div class="lang-list">
            <template v-for="(item, index) in languagesList">
              <div class="lang-list-item hoverScale" v-if="item.key != activeLanguages" :key="index" @click="changeLanguage(item)">
                <img class="" :src="item.imgSrc" />
                {{ item.language }}
              </div>
            </template>
          </div>
          <div slot="reference" class="language-btn u-flex u-row-around">
            <img class="" :src="activeLanguageItem.imgSrc" />
            {{ activeLanguageItem.language }}
            <i class="el-icon-arrow-down"></i>
          </div>
        </el-popover>
      </div>
    </div>
    <div :class="['navBox', isFixed ? 'headerFixed' : '']" @mouseleave="subNavShow = false">
      <div class="nav i-fixedWidth u-flex u-row-center">
        <div class="u-flex" @click="$router.push('/demo')">
          <img class="logo" :src="$t('image.logo')" />
        </div>
        <div class="navBar u-flex u-row-between">
          <div class="navBar-item hoverScale" @mousemove="hoverShow(1)">
            <span ref="navBar1">{{ $t('Main.Main0') }}</span>
            <i class="el-icon-arrow-down"></i>
          </div>
          <div>/</div>
          <div class="navBar-item hoverScale" @mousemove="hoverShow(2)">
            <span ref="navBar2">{{ $t('Main.Main8') }}</span>
            <i class="el-icon-arrow-down"></i>
          </div>
          <div>/</div>
          <div class="navBar-item hoverScale" @mousemove="hoverShow(3)">
            <span ref="navBar3">{{ $t('Main.Main17') }}</span>
            <i class="el-icon-arrow-down"></i>
          </div>
          <div>/</div>
          <div class="navBar-item hoverScale" @mousemove="hoverShow(4)">
            <span ref="navBar4">{{ $t('Main.Main21') }}</span>
            <i class="el-icon-arrow-down"></i>
          </div>
          <div>/</div>
          <div class="navBar-item hoverScale" @mousemove="hoverShow(5)">
            <span ref="navBar5">{{ $t('Main.Main25') }}</span>
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
      </div>
      <transition name="el-fade-in-linear">
        <div class="subNav" v-show="subNavShow">
          <div v-if="subActiveIndex == 1" class="subNav-item" :style="{ left: `${left1}px` }">
            <div>
              <router-link to="/forex" class="hoverScale">{{ $t('Main.Main1') }}</router-link>
            </div>
            <div>
              <router-link to="/home" class="hoverScale">{{ $t('Main.Main2') }}</router-link>
            </div>
            <div>
              <router-link to="/home" class="hoverScale">{{ $t('Main.Main3') }}</router-link>
            </div>
          </div>
          <div v-if="subActiveIndex == 2" class="subNav-item" :style="{ left: `${left2}px` }">
            <div>
              <router-link to="/home" class="hoverScale">{{ $t('Main.Main9') }}</router-link>
            </div>
            <div>
              <router-link to="/home" class="hoverScale">{{ $t('Main.Main10') }}</router-link>
            </div>
          </div>
          <div v-if="subActiveIndex == 3" class="subNav-item" :style="{ left: `${left3}px` }">
            <div>
              <router-link to="/home" class="hoverScale">{{ $t('Main.Main18') }}</router-link>
            </div>
            <div>
              <router-link to="/home" class="hoverScale">{{ $t('Main.Main19') }}</router-link>
            </div>
            <div>
              <router-link to="/home" class="hoverScale">{{ $t('Main.Main20') }}</router-link>
            </div>
            <div>
              <router-link to="/home" class="hoverScale">{{ $t('Main.Main11') }}</router-link>
            </div>
            <div>
              <router-link to="/home" class="hoverScale">{{ $t('Main.Main12') }}</router-link>
            </div>
            <div>
              <router-link to="/home" class="hoverScale">{{ $t('Main.Main13') }}</router-link>
            </div>
            <div>
              <router-link to="/home" class="hoverScale">{{ $t('Main.Main14') }}</router-link>
            </div>
          </div>
          <div v-if="subActiveIndex == 4" class="subNav-item" :style="{ left: `${left4}px` }">
            <div>
              <router-link to="/home" class="hoverScale">{{ $t('Main.Main22') }}</router-link>
            </div>
            <div>
              <router-link to="/home" class="hoverScale">{{ $t('Main.Main23') }}</router-link>
            </div>
          </div>
          <div v-if="subActiveIndex == 5" class="subNav-item" :style="{ left: `${left5}px` }">
            <div>
              <router-link to="/home" class="hoverScale">{{ $t('Main.Main26') }}</router-link>
            </div>
            <div>
              <router-link to="/home" class="hoverScale">{{ $t('Main.Main27') }}</router-link>
            </div>
            <div>
              <router-link to="/home" class="hoverScale">{{ $t('Main.Main28') }}</router-link>
            </div>
            <div>
              <router-link to="/home" class="hoverScale">{{ $t('Main.Main29') }}</router-link>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { scrollMixins } from '@/utils/mixins';

export default {
  mixins: [scrollMixins],

  data() {
    return {
      languagesList: [],
      subNavShow: false,
      subActiveIndex: 0,
      isFixed: false,
      left1: 0,
      left2: 0,
      left3: 0,
      left4: 0,
      left5: 0,
    };
  },

  components: {},

  computed: {
    activeLanguageItem() {
      return this.languagesList.find((item) => item.key == this.activeLanguages);
    },
    activeLanguages() {
      return this.$store.getters.language;
    },
  },
  watch: {
    activeLanguages() {
      this.$nextTick(() => {
        for (let i = 1; i < 6; i++) {
          this[`left${i}`] = this.$refs[`navBar${i}`].getBoundingClientRect().left;
        }
      });
    },
  },

  created() {
    Object.keys(this.$i18n.messages).forEach((key) => {
      let item = {
        key,
        language: this.$i18n.messages[key].language,
        imgSrc: this.$i18n.messages[key].languageImg,
      };
      this.languagesList.push(item);
      
    });
  },
  methods: {
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 104) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
    changeLanguage(item) {
      this.$store.dispatch('setLanguage', item.key);
      this.$i18n.locale = item.key;
    },
    hoverShow(index) {
      let leftPx = this.$refs[`navBar${index}`].getBoundingClientRect().left;
      if (this[`left${index}`] === 0) {
        this[`left${index}`] = leftPx;
      }
      this.subNavShow = true;
      this.subActiveIndex = index;
    },
  },
};
</script>
<style scoped lang="scss">
.Vietnam {
  .header .language-nav .language-nav-right .u-button {
    width: 100px;
  }
  .header .nav {
    padding-left: 160px;
  }
}

.headerFixed {
  position: fixed !important;
  z-index: 1000;
  top: 0 !important;
  width: 100%;
}

.header {
  width: 100%;
  font-weight: 700;
  position: relative;
  @extend .fontRobotoBold;
  .fixedAuto {
    height: 97px;
  }
  .language-nav {
    width: 100%;
    height: 40px;
    background-color: #030234;
    color: #fff;

    .language-nav-right {
      justify-content: end;
      .contactUs {
        font-size: 12px;
        font-weight: bold;
        color: #fefefe;
        margin-right: 24px;
      }
      .u-button {
        width: 70px;
        height: 18px;
        background: #ffc631;
        font-size: 12px;
        font-weight: bold;
        color: #000000;
        line-height: 10px;

        &:hover {
          background-color: #fff;
        }
      }
      .language-btn {
        margin-left: 18px;
        margin-right: 250px;
        cursor: pointer;

        font-size: 12px;
        font-weight: bold;
        color: #fefefe;

        img {
          width: 25px;
          margin-right: 7px;
        }
        .el-icon-arrow-down {
          margin-left: 8px;
        }
      }
    }
  }
  .navBox {
    background-color: rgba(255, 255, 255, 0.8);
    transition: all 0.5s;
    top: -100%;
  }
  .nav {
    height: 64px;
    color: #4f4f4f;
    font-size: 14px;

    .logo {
      width: 159.2px;
      cursor: pointer;
    }
    .navBar {
      margin-left: 45px;
      .navBar-item {
        cursor: pointer;
        margin-left: 20px;
        i {
          margin-left: 5px;
          margin-right: 10px;
        }
      }
    }
  }
  .subNav {
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.6);
    position: absolute;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    padding-top: 14.4px;
    padding-bottom: 14.4px;
    line-height: 21.6px;
    z-index: 1000;
    .subNav-item {
      position: relative;
      .hoverScale {
        cursor: pointer;
        &:hover {
          transform: scale(1.3);
        }
      }
      div {
        display: flex;
      }
    }
  }
}
</style>
