export const scrollMixins = {
  data() {
    return {
      animationTimeList: [],
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll); // 监听页面滚动
  },
  methods: {
    // 获取页面滚动距离
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      console.log(scrollTop, '滚动距离');
    },

    isElementsVisible(element) {
      let scrollTop = document.documentElement.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let rect = element.offsetTop;
      let bottom = scrollTop + clientHeight;
      return bottom >= rect;
    },

    animationList(list) {
      let index = list.findIndex((flag) => flag === false);
      if (index > -1) {
        list.map((flag, index) => {
          return new Promise((resolve, rejects) => {
            setTimeout(() => {
              this.$set(list, index, true);
              resolve();
            }, 650 * (index + 1));
          });
        });
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
